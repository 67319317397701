// src/components/Layout/Navbar.js

import React from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate, Link } from 'react-router-dom'; // Добавлен Link

const Navbar = () => {
    const { isAuthenticated, logout } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    return (
        <nav className="bg-blue-500 text-white p-4 shadow-md flex justify-between items-center">
            <div className="text-xl font-bold">ТопGO GPT beta</div>
            
            {isAuthenticated && (
                <div className="flex space-x-1">
                     {/* Кнопка НОВОСТИ */}
                    <Link
                        to="/chat"
                        className="px-3 py-1 bg-green-600 rounded hover:bg-blue-700 transition duration-200"
                    >
                        ЧАТ
                    </Link>

                    {/* Кнопка НОВОСТИ */}
                    <Link
                        to="/news"
                        className="px-3 py-1 bg-blue-600 rounded hover:bg-blue-700 transition duration-200"
                    >
                        НОВОСТИ
                    </Link>
                    {/* Кнопка Выйти */}
                    <button
                        onClick={handleLogout}
                        className="px-3 py-1 bg-red-500 rounded hover:bg-red-600 transition duration-200"
                    >
                        Выйти
                    </button>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
