// src/pages/AdminPage.js
import React from 'react';
import Navbar from '../components/Layout/Navbar';
import UsersList from '../components/Admin/UsersList';

const AdminPage = () => {
    return (
        <div className="flex flex-col h-screen">
            <Navbar />
            <div className="flex-1 bg-gray-50 overflow-y-auto">
                <UsersList />
            </div>
        </div>
    );
};

export default AdminPage;
