// src/components/Chat/ChatList.js

import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { ChatBubbleLeftIcon } from '@heroicons/react/24/outline'; // Обновлённый импорт

const ChatList = ({ onSelectChat }) => {
    const [chats, setChats] = useState([]);
    const [newChatTitle, setNewChatTitle] = useState('');
    const [showCreateChat, setShowCreateChat] = useState(false);
    const [error, setError] = useState('');

    const loadChats = async () => {
        try {
            const response = await api.get('/chat');
            setChats(response.data);
            console.log('Чаты загружены:', response.data);
        } catch (error) {
            console.error('Ошибка загрузки чатов:', error);
        }
    };

    useEffect(() => {
        loadChats();
    }, []);

    const handleCreateChat = async (e) => {
        e.preventDefault();
        if (!newChatTitle.trim()) {
            setError('Название чата не может быть пустым.');
            return;
        }
        try {
            const response = await api.post('/chat/create', { title: newChatTitle });
            console.log('Чат создан:', response.data);
            setChats([...chats, response.data]);
            setNewChatTitle('');
            setShowCreateChat(false);
            setError('');
            onSelectChat(response.data); // Автоматически выбрать новый чат
        } catch (error) {
            console.error('Ошибка создания чата:', error);
            setError(error.response?.data?.message || 'Ошибка создания чата');
        }
    };

    return (
        <div className="space-y-2">
            <button
                onClick={() => setShowCreateChat(!showCreateChat)}
                className="w-full bg-green-500 text-white py-2 rounded hover:bg-green-600 transition duration-200 flex items-center justify-center"
            >
                {showCreateChat ? 'Отмена' : 'Новый чат'}
            </button>

            {showCreateChat && (
                <form onSubmit={handleCreateChat} className="mb-4">
                    {error && <div className="bg-red-100 text-red-700 p-2 rounded mb-2">{error}</div>}
                    <input
                        type="text"
                        value={newChatTitle}
                        onChange={(e) => setNewChatTitle(e.target.value)}
                        placeholder="Название чата"
                        className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                    />
                    <button
                        type="submit"
                        className="mt-2 w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-200"
                    >
                        Создать
                    </button>
                </form>
            )}

            {chats.map((chat) => (
                <div
                    key={chat.id}
                    className="p-2 bg-gray-100 rounded cursor-pointer hover:bg-gray-200 flex items-center"
                    onClick={() => onSelectChat(chat)}
                >
                    <ChatBubbleLeftIcon className="h-5 w-5 text-gray-500 mr-2" />
                    <span>{chat.title || `Чат #${chat.id}`}</span>
                </div>
            ))}

            {chats.length === 0 && !showCreateChat && (
                <div className="text-gray-500">У вас еще нет чатов. Создайте новый чат!</div>
            )}
        </div>
    );
};

export default ChatList;

