// src/pages/HomePage.js

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/chat');
        } else {
            navigate('/login');
        }
    }, [navigate]);

    return null;
};

export default HomePage;
