// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Chat/ChatWindow.css */

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(150, 149, 149, 0.7); /* Полупрозрачный белый фон */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Убедитесь, что оверлей поверх остальных элементов */
}


.loading-gif {
    width: 250px; /* Размер GIF-анимации */
    height: 300px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Chat/ChatWindow.css"],"names":[],"mappings":"AAAA,uCAAuC;;AAEvC;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC,EAAE,6BAA6B;IACnE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,sDAAsD;AACzE;;;AAGA;IACI,YAAY,EAAE,wBAAwB;IACtC,aAAa;AACjB","sourcesContent":["/* src/components/Chat/ChatWindow.css */\r\n\r\n.loading-overlay {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    background: rgba(150, 149, 149, 0.7); /* Полупрозрачный белый фон */\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    z-index: 1000; /* Убедитесь, что оверлей поверх остальных элементов */\r\n}\r\n\r\n\r\n.loading-gif {\r\n    width: 250px; /* Размер GIF-анимации */\r\n    height: 300px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
