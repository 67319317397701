// src/pages/NewsPage.js

import React, { useState } from 'react';

const News = () => {
    const [news, setNews] = useState([
        // Пример начальных новостей
        {
            id: 1,
            title: 'Добро пожаловать в ТопGO GPT beta!',
            content: 'Я очень рад вам представить новую платформу для общения и решения корпоративных задач на основе искусственного интеллекта!🤩\u00a0В самое ближайшее время количество функций и возможностей станет больше!Если у вас остались вопросы - пишите https://t.me/andruwrus',
            date: '28.09.2024 18:02',
            author: 'Дворянкин Андрей',
        },
    ]);

    return (

        
        <div className="p-6">
            <h1 className="text-3xl font-bold mb-4">НОВОСТИ</h1>

            {/* Форма добавления новой новости 
           <form onSubmit={handleAddNews} className="mb-6">
                {error && <div className="bg-red-100 text-red-700 p-2 rounded mb-2">{error}</div>}
                <div className="mb-4">
                    <label className="block mb-1 font-semibold">Заголовок</label>
                    <input
                        type="text"
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                        placeholder="Введите заголовок новости"
                        className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>
                <div className="mb-4">
                    <label className="block mb-1 font-semibold">Содержание</label>
                    <textarea
                        value={newContent}
                        onChange={(e) => setNewContent(e.target.value)}
                        placeholder="Введите содержание новости"
                        className="w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                        rows="4"
                    ></textarea>
                </div>
                <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200"
                >
                    Добавить Новость
                </button>
            </form> */}

            {/* Список новостей */}
            <div className="space-y-4">
                {news.map((item) => (
                    <div key={item.id} className="p-4 border rounded shadow-sm bg-white">
                        <h2 className="text-xl font-bold">{item.title}</h2>
                        <p className="text-gray-600 text-sm">Дата: {item.date}</p>
                        <p className="text-gray-600 text-sm mb-1">Автор: {item.author}</p>
                        <br></br>
                        <p>{item.content}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default News;
