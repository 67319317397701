// src/components/Chat/ChatWindow.js

import React, { useEffect, useState, useRef, useContext } from 'react';
import api from '../../services/api';
import { PaperClipIcon, ArrowUpIcon } from '@heroicons/react/24/solid'; // Обновлённый импорт
import loadingGif from '../../assets/loading.gif'; // Убедитесь, что файл существует
import './ChatWindow.css'; // Стили для анимации загрузки(а нужна ли она?)
import { AuthContext } from '../../context/AuthContext';

const ChatWindow = ({ chat }) => {
    const { logout } = useContext(AuthContext);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const messagesEndRef = useRef(null);
    const [error, setError] = useState('');
    const [enlargedImageUrl, setEnlargedImageUrl] = useState(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        const loadMessages = async () => {
            try {
                console.log('Загрузка сообщений для чата:', chat.id);
                const response = await api.get(`/chat/${chat.id}/messages`);
                console.log('Сообщения получены:', response.data);
                setMessages(response.data);
            } catch (error) {
                console.error('Ошибка загрузки сообщений:', error);
                setError('Не удалось загрузить сообщения.');
            }
        };

        if (chat) {
            loadMessages();
        }
    }, [chat]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim() && !selectedFile) return;

        setLoading(true);

        const formData = new FormData();
        formData.append('chatId', chat.id);
        formData.append('content', newMessage);
        if (selectedFile) {
            formData.append('file', selectedFile);
        }

        // Добавляем сообщение пользователя
        const userMessage = {
            id: Date.now(),
            chatId: chat.id,
            content: newMessage,
            role: 'user',
            fileType: selectedFile ? (selectedFile.type.startsWith('image/') ? 'image' : 'document') : null,
            filePath: null,
        };

        setMessages((prevMessages) => [...prevMessages, userMessage]);

        // Добавляем сообщение "ЖДЁМ ОТВЕТ..."
        const waitingMessage = {
            id: Date.now() + 1,
            chatId: chat.id,
            content: 'ТопGO GPT готовит ответ....',
            role: 'assistant',
            fileType: null,
            filePath: null,
        };

        setMessages((prevMessages) => [...prevMessages, waitingMessage]);

        setNewMessage('');
        setSelectedFile(null);
        setError('');

        try {
            const response = await api.post('/chat/message', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Ответ от сервера:', response.data);

            // Обновляем последнее сообщение (ЖДЁМ ОТВЕТ...) реальным ответом ассистента
            setMessages((prevMessages) => {
                // Удаляем последнее сообщение (ЖДЁМ ОТВЕТ...)
                const messagesWithoutWaiting = prevMessages.slice(0, -1);
                return [...messagesWithoutWaiting, response.data];
            });
        } catch (error) {
            console.error('Ошибка отправки сообщения:', error);
            setError(error.response?.data?.message || 'Ошибка отправки сообщения.');
            // Удаляем сообщение "ЖДЁМ ОТВЕТ..." при ошибке
            setMessages((prevMessages) => prevMessages.slice(0, -1));
        } finally {
            setLoading(false);
        }
    };

    const handleImageClick = (imageUrl) => {
        setEnlargedImageUrl(imageUrl);
    };

    const closeImageModal = () => {
        setEnlargedImageUrl(null);
    };

    const handleFileButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setSelectedFile(e.target.files[0]);
        }
    };

    return (
        <div className="flex flex-col h-full">
            {/* Header */}
            <div className="bg-white shadow p-4 flex justify-between items-center">
                <h2 className="text-2xl font-bold">Текущий чат - {chat.title || `Чат #${chat.id}`}</h2>
                
            </div>

            {/* Messages */}
            <div className="flex-1 p-4 overflow-y-auto bg-gray-50">
                {error && <div className="bg-red-100 text-red-700 p-2 rounded mb-2">{error}</div>}
                {messages.map((msg, index) => (
                    <div
                        key={msg.id || index}
                        className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'} mb-2`}
                    >
                        <div
                            className={`max-w-xs ${
                                msg.role === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
                            } px-4 py-2 rounded-lg`}
                        >
                            {msg.fileType === 'image' && msg.filePath ? (
                                <img
                                    src={`https://node.topgo.delivery/${msg.filePath}`}
                                    alt="image"
                                    className="max-w-full h-auto rounded cursor-pointer"
                                    onClick={() => handleImageClick(`https://node.topgo.delivery/${msg.filePath}`)}
                                />
                            ) : msg.filePath ? (
                                <a
                                    href={`https://node.topgo.delivery${msg.filePath}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="underline text-blue-300 hover:text-blue-400"
                                >
                                    Скачать файл
                                </a>
                            ) : (
                                <span>{msg.content}</span>
                            )}
                        </div>
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>

            {/* Input Area */}
            <form onSubmit={handleSendMessage} className="flex p-4 bg-white shadow">
                <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Введите сообщение..."
                    className="flex-1 px-3 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                    disabled={loading}
                />

                {/* Кнопка загрузки файла */}
                <button
                    type="button"
                    onClick={handleFileButtonClick}
                    className="ml-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                    disabled={loading}
                    aria-label="Загрузить файл"
                >
                    <PaperClipIcon className="h-6 w-6" />
                </button>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    className="hidden"
                    accept="image/*,.pdf,.doc,.docx,.txt,.xlsx,.xlsx"
                />

                {/* Кнопка отправки */}
                <button
                    type="submit"
                    className="ml-2 bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 transition duration-200 flex items-center justify-center"
                    disabled={loading}
                    aria-label="Отправить сообщение"
                >
                    <ArrowUpIcon className="h-5 w-5" />
                </button>
            </form>

            {/* Image Modal */}
            {enlargedImageUrl && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
                    onClick={closeImageModal}
                >
                    <img
                        src={enlargedImageUrl}
                        alt="Enlarged"
                        className="max-w-full max-h-full"
                        onClick={(e) => e.stopPropagation()}
                    />
                </div>
            )}

            {/* Loading Overlay */}
            {loading && (
                <div className="loading-overlay" role="alert" aria-busy="true">
                    <img src={loadingGif} alt="Загрузка..." className="loading-gif" />
                </div>
            )}
        </div>
    );

};

export default ChatWindow;






