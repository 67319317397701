// src/components/Admin/UsersList.js
import React, { useEffect, useState } from 'react';
import api from '../../services/api';

const UsersList = () => {
    const [users, setUsers] = useState([]);

    const loadUsers = async () => {
        try {
            const response = await api.get('/admin/users');
            setUsers(response.data);
        } catch (error) {
            console.error('Ошибка загрузки пользователей:', error);
        }
    };

    useEffect(() => {
        loadUsers();
    }, []);

    return (
        <div className="p-4">
            <h3 className="text-xl font-semibold mb-4">Пользователи</h3>
            <table className="min-w-full bg-white rounded shadow">
                <thead>
                    <tr>
                        <th className="py-2 px-4 border-b">ID</th>
                        <th className="py-2 px-4 border-b">Имя пользователя</th>
                        <th className="py-2 px-4 border-b">Роль</th>
                        <th className="py-2 px-4 border-b">Дата создания</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.id} className="text-center">
                            <td className="py-2 px-4 border-b">{user.id}</td>
                            <td className="py-2 px-4 border-b">{user.username}</td>
                            <td className="py-2 px-4 border-b">{user.role}</td>
                            <td className="py-2 px-4 border-b">
                                {new Date(user.createdAt).toLocaleString()}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UsersList;
