// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';
import ChatPage from './pages/ChatPage';
import AdminPage from './pages/AdminPage';
import HomePage from './pages/HomePage';
import NewsPage from './pages/NewsPage'; // Импорт новой страницы
import { AuthProvider, AuthContext } from './context/AuthContext';

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route
                        path="/register"
                        element={
                            <AuthContext.Consumer>
                                {({ isAuthenticated }) =>
                                    !isAuthenticated ? <RegisterPage /> : <Navigate to="/chat" />
                                }
                            </AuthContext.Consumer>
                        }
                    />
                    <Route
                        path="/login"
                        element={
                            <AuthContext.Consumer>
                                {({ isAuthenticated }) =>
                                    !isAuthenticated ? <LoginPage /> : <Navigate to="/chat" />
                                }
                            </AuthContext.Consumer>
                        }
                    />
                    <Route
                        path="/chat"
                        element={
                            <AuthContext.Consumer>
                                {({ isAuthenticated }) =>
                                    isAuthenticated ? <ChatPage /> : <Navigate to="/login" />
                                }
                            </AuthContext.Consumer>
                        }
                    />

<Route
                        path="/news"
                        element={
                            <AuthContext.Consumer>
                                {({ isAuthenticated }) =>
                                    isAuthenticated ? <NewsPage /> : <Navigate to="/login" />
                                }
                            </AuthContext.Consumer>
                        }
                    />
                    
                    <Route
                        path="/admin"
                        element={
                            <AuthContext.Consumer>
                                {({ isAuthenticated, getUserRole }) =>
                                    isAuthenticated && getUserRole() === 'admin' ? (
                                        <AdminPage />
                                    ) : (
                                        <Navigate to="/login" />
                                    )
                                }
                            </AuthContext.Consumer>
                        }
                    />
                </Routes>
            </Router>
        </AuthProvider>
    );
};

export default App;
