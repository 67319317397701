// src/pages/ChatPage.js

import React, { useState, useEffect } from 'react';
import Navbar from '../components/Layout/Navbar';
import ChatList from '../components/Chat/ChatList';
import ChatWindow from '../components/Chat/ChatWindow';
import { useNavigate } from 'react-router-dom';

const ChatPage = () => {
    const [selectedChat, setSelectedChat] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
        }
    }, [navigate]);

    return (
        <div className="flex flex-col h-screen">
            {/* Навбар */}
            <Navbar />

            {/* Основная область чата */}
            <div className="flex flex-1 overflow-hidden">
                {/* Сайдбар со списком чатов */}
                <div className="w-1/4 bg-gray-100 p-4 overflow-y-auto border-r">
                    <ChatList onSelectChat={setSelectedChat} />
                </div>

                {/* Основная область чата */}
                <div className="w-3/4 flex flex-col">
                    {selectedChat ? (
                        <ChatWindow chat={selectedChat} />
                    ) : (
                        <div className="flex-1 flex items-center justify-center">
                            <h2 className="text-2xl text-gray-500">Выберите чат для начала общения.</h2>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChatPage;


